import { createSelector } from 'reselect'

export const getCurrentUser = (state) => state.currentUser // typeof User is a Map

export const getCurrentUserObj = createSelector(
  getCurrentUser,
  (currentUser) => currentUser?.toJS() || {},
)

export const getUserToken = createSelector(getCurrentUserObj, (user) => user.userToken)

export default {
  getUserToken,
  getCurrentUser,
  getCurrentUserObj,
}
