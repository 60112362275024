import axios from 'axios'

const FIRST_PAGE_MILLIS = 9999999999999

const requestConfig = {
  baseURL: '/api/',
  headers: { 'Content-Type': 'application/json' },
}

export const v1WithoutToken = axios.create(requestConfig)

export function getTokenHeaders(userToken) {
  return userToken ? { Authorization: `Popdaily ${userToken}` } : undefined
}

export function v1PostWithToken(url, data, userToken) {
  const headers = getTokenHeaders(userToken)
  return v1WithoutToken.post(url, data, { headers })
}
export function v1GetWithToken(url, params, userToken) {
  const headers = getTokenHeaders(userToken)
  return v1WithoutToken.get(url, { params, headers })
}

export const setV1BaseUrl = (url) => {
  v1WithoutToken.defaults.baseURL = url
}

export const fetchUserInfo = (userToken) => v1PostWithToken('/user/private/get', userToken)

export const fetchUserPermission = (userToken) =>
  v1GetWithToken('/permission/get', undefined, userToken)

export const updateUserBirthday = (birthday, userToken) =>
  v1PostWithToken('/user/save', { type: 'private', birthday }, userToken)

export const fetchPost = (postId, userToken) =>
  v1PostWithToken('/post/get', { postID: postId }, userToken)

export const fetchExplorePosts = ({ clas, mode, page, score, touch }, userToken) =>
  v1PostWithToken(
    '/list/post',
    { type: 'explore', clas, mode, page, score, param: '', touch },
    userToken,
  )

export const fetchReply = (postId, userToken) =>
  v1PostWithToken('/reply/get', { postID: postId }, userToken)

export const fetchTopicList = (postId, userToken) =>
  v1PostWithToken('/topic/getList', { postID: postId }, userToken)

export const fetchSecretPosts = (page = FIRST_PAGE_MILLIS, userToken) =>
  v1PostWithToken('/list/post', { type: 'explore', clas: 'secret', mode: 'new', page }, userToken)

export const fetchLocations = ({ sort, limit, popselect, random }, userToken) => {
  return v1PostWithToken('/map/sort', { sort, limit, popselect, random }, userToken)
}

export const searchLocations = ({ query, page }, userToken) => {
  return v1PostWithToken('/map/search', { query, page }, userToken)
}

export const postComment = ({ postID, replyID, text, isSecret }, userToken) => {
  return v1PostWithToken(
    '/editor/reply',
    {
      postID,
      b: replyID,
      text,
      secret: isSecret,
    },
    userToken,
  )
}

export const fetchPopBoxGiftsList = ({ type = 'ongoing', page = 0, limit = 1 }, userToken) => {
  return v1PostWithToken(
    '/gift/list',
    {
      type,
      page,
      limit,
    },
    userToken,
  )
}

/**
 * example response.data :
{
  "code": 11003,
  "alias": "SUCCESS_TOKEN_GET",
  "msg": "token取得",
  "uid": "fb.1234",
  "token": "fb.1234.4e6c6eca-f4c5-4d70-8252-77db6c8dab28",
  "newUser": 0,
  "admin": 1
}
*/
export const loginWithFacebookToken = ({ facebookToken, platform }, userToken) => {
  return v1PostWithToken('/login/fb/token', { token: facebookToken, platform }, userToken)
}

/**
 * example response.data:
{
  "code": 11003,
  "alias": "SUCCESS_TOKEN_GET",
  "msg": "token取得",
  "uid": "firebase.YcH643GJp4NksXMbGAVlM6W4gMM2",
  "token": "firebase.YcH643GJp4NksXMbGAVlM6W4gMM2.52bf1773-d758-4edc-95e3-f60d2081f1a8",
  "newUser": 1
 */
export const loginWithFirebaseToken = ({ firebaseIdToken, platform }, userToken) => {
  return v1PostWithToken('/login/firebase', { token: firebaseIdToken, platform }, userToken)
}

/**
 * example response.data:
 *   {
 *     param: 0,
 *     name: '訪客 10195668',
 *     img: '/statics/images/avatar/animal_8_0.jpg',
 *     uid: 'guest.10195668',
 *     mid: 10195668,
 *     type: 'guest'
 *   }
 *   {
 *     param: 0,
 *     code: 15002,
 *     msg: '使用者資料取得成功',
 *     mid: '25',
 *     uid: 'email.creators+1@sugoigroup.tw',
 *     check: 0,
 *     num1: { like: 0, view: 9, follow: 0, saved: 0, reply: 0, post: 1 },
 *     num2: { like: 0, view: 0, follow: 0, saved: 0, reply: 0 },
 *     type: 'email',
 *     role: {},
 *     creatorInfo: {},
 *     t: 1611824956540,
 *     signUpPlatform: 'Web',
 *     valid: false
 *   }
 */
export const validateToken = ({ token }) => {
  return v1PostWithToken('/token/check', { token })
}

/**
 * example response.data:
{
  "code": 11002,
  "alias": "SUCCESS_TOKEN_GET",
  "msg": "訪客token取得",
  "uid": "guest.136823035",
  "token": "guest.136823035.2b2522e3-d5ac-4d52-8dda-acc448d1a963"
}
 */
export const getGuestToken = () => {
  return v1GetWithToken('/login/guest')
}

export const fetchTrendPosts = (beforeTimeMillis = FIRST_PAGE_MILLIS, userToken) => {
  return v1PostWithToken(
    '/list/post',
    {
      type: 'explore',
      clas: 'trend',
      page: beforeTimeMillis,
      mode: 'new',
    },
    userToken,
  )
}
