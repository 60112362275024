import constantCreator from '../libs/constantCreator'
import { v1PostWithToken } from '../api/v1'
import { getUserToken } from '../currentUser/selector'

const createConst = constantCreator('notify')

export const GET_NOTIFY_STATE = createConst('GET_NOTIFY_STATE')
export const GET_NOTIFY_STATE_SUCCESS = createConst('GET_NOTIFY_STATE_SUCCESS')
export const GET_NOTIFY_STATE_FAILED = createConst('GET_NOTIFY_STATE_FAILED')
export function checkNotify({ page = undefined }) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_NOTIFY_STATE })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/notify/check', { page }, userToken)
      dispatch({ type: GET_NOTIFY_STATE_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_NOTIFY_STATE_FAILED, error })
    }
  }
}

export const CLEAR_NOTIFY_STATE = createConst('CLEAR_NOTIFY_STATE')
export const CLEAR_NOTIFY_STATE_SUCCESS = createConst('CLEAR_NOTIFY_STATE_SUCCESS')
export const CLEAR_NOTIFY_STATE_FAILED = createConst('CLEAR_NOTIFY_STATE_FAILED')
export function clearNotify({ page = undefined }) {
  return async (dispatch, getState) => {
    dispatch({ type: CLEAR_NOTIFY_STATE })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/notify/clear', { page }, userToken)
      dispatch({ type: CLEAR_NOTIFY_STATE_SUCCESS, response })
    } catch (error) {
      dispatch({ type: CLEAR_NOTIFY_STATE_FAILED, error })
    }
  }
}

export const GET_USER_NOTIFY_LIST = createConst('GET_USER_NOTIFY_LIST')
export const GET_USER_NOTIFY_LIST_SUCCESS = createConst('GET_USER_NOTIFY_LIST_SUCCESS')
export const GET_USER_NOTIFY_LIST_FAILED = createConst('GET_USER_NOTIFY_LIST_FAILED')
export function getUserNotifyList({ page }) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_USER_NOTIFY_LIST })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/notify/list', { page }, userToken)
      dispatch({ type: GET_USER_NOTIFY_LIST_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_USER_NOTIFY_LIST_FAILED, error })
    }
  }
}

export const GET_SYSTEM_NOTIFY_LIST = createConst('GET_SYSTEM_NOTIFY_LIST')
export const GET_SYSTEM_NOTIFY_LIST_SUCCESS = createConst('GET_SYSTEM_NOTIFY_LIST_SUCCESS')
export const GET_SYSTEM_NOTIFY_LIST_FAILED = createConst('GET_SYSTEM_NOTIFY_LIST_FAILED')
export function getSystemNotifyList() {
  return async (dispatch, getState) => {
    dispatch({ type: GET_SYSTEM_NOTIFY_LIST })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/popadmin/getNotification', {}, userToken)
      dispatch({ type: GET_SYSTEM_NOTIFY_LIST_SUCCESS, response })
    } catch (error) {
      dispatch({ type: GET_SYSTEM_NOTIFY_LIST_FAILED, error })
    }
  }
}

export const UPDATE_FOLLOW_STATE = createConst('UPDATE_FOLLOW_STATE')
export const UPDATE_FOLLOW_STATE_SUCCESS = createConst('UPDATE_FOLLOW_STATE_SUCCESS')
export const UPDATE_FOLLOW_STATE_FAILED = createConst('UPDATE_FOLLOW_STATE_FAILED')
export function updateFollowState({ followID, follow }) {
  return async (dispatch, _getState, { alert }) => {
    dispatch({ type: UPDATE_FOLLOW_STATE, followID })

    try {
      const userToken = getUserToken(_getState())
      const { data: response } = await v1PostWithToken(
        '/user/follow',
        {
          followID,
          follow,
        },
        userToken,
      )
      dispatch({ type: UPDATE_FOLLOW_STATE_SUCCESS, response, followID })
      if (response.code !== 15006) {
        // Ken Kuan: not sure why need to alert full response content
        alert(JSON.stringify(response))
      }
    } catch (error) {
      dispatch({ type: UPDATE_FOLLOW_STATE_FAILED, error, followID })
    }
  }
}

export const READ_NOTIFY = createConst('READ_NOTIFY')
export const READ_NOTIFY_SUCCESS = createConst('READ_NOTIFY_SUCCESS')
export const READ_NOTIFY_FAILED = createConst('READ_NOTIFY_FAILED')
export function readNotify({ id }) {
  return async (dispatch, getState) => {
    dispatch({ type: READ_NOTIFY, id })

    try {
      const userToken = getUserToken(getState())
      const { data: response } = await v1PostWithToken('/notify/read', { id }, userToken)
      dispatch({ type: READ_NOTIFY_SUCCESS, response, id })
    } catch (error) {
      dispatch({ type: READ_NOTIFY_FAILED, error, id })
    }
  }
}
